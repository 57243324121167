<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getDocumentDigitizationISC"
  :isLoading="isLoading"
  :saveReport="getDocumentDigitizationISCExcel"
)
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'created_at', text: this.$i18n.t('tableHeaders.created_at'), sortable: false },
        { value: 'sailor.id', text: this.$i18n.t('tableHeaders.sailor_id'), sortable: false },
        { value: 'sailor.full_name_ukr', text: this.$i18n.t('tableHeaders.sailor_full_name'), sortable: false },
        { value: 'sailor.full_name_eng', text: this.$i18n.t('tableHeaders.sailor_full_name_eng'), sortable: false },
        { value: 'manager_full_name', text: this.$i18n.t('tableHeaders.manager_full_name'), sortable: false },
        { value: 'verificationDocumentTypes', text: this.$i18n.t('tableHeaders.type_document'), sortable: false },
        { value: 'status_document', text: this.$i18n.t('tableHeaders.status_document'), sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.documentDigitizationISC,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getDocumentDigitizationISC', 'getDocumentDigitizationISCExcel'])
  }
}
</script>
